import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { authenticationGuard, globalstate$, config } from '@smf/ui-util-app';
import { Box, CircularProgress, Typography } from '@mui/material';
import { redirectInApp } from '../../utils/helper';

/**
 * This Custom HOC is responsible for teh following operations :
 *  1. Provide a Auth guard over the Child Component
 *  2. On component Mount, check the logged in User session from Util app
 *  3. Subscribe & Synchronize the logged in user flag and access token
 *     from the global state using observable
 *
 * @param {} props
 *
 * @author Amlan Saha
 *
 * @returns Render Child Component based on session flag
 */
const AuthenticatedRoute = (props) => {
  // is Authorized flag, having sync through global state subscription
  const [isAuthorized, setIsAuthorized] = useState(
    globalstate$.value.isLoggedInUser
  );
  // is loading flag, having sync through global state subscription
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let globalState;

    const setToken = async () => {
      try {
        // Initiate the call to the Util module centralize api to validate
        // the token in local storage,
        // if not present in local storage, it checks for the Access Token
        // in the Hash enabled redirect URL,
        // If found set it into the local storage
        // else, return false
        const isLoggedInStatus = await authenticationGuard.isloggedInUser();
        if (isLoggedInStatus !== undefined) {
          if (!isLoggedInStatus) {
            props.history.push('/login');
          } else {
            props.history.push(
              window.location.pathname?.replace(/\/$/, '') || props.path
            );
            // Subscribe to Global state
            globalState = globalstate$.subscribe(
              ({ sessionToken, isLoggedInUser }) => {
                setIsAuthorized(isLoggedInUser);
                setIsLoading(!sessionToken);
              }
            );
            /**
             * Handle the redirection in the app
             */
            redirectInApp();
          }
        }
      } catch (e) {
        console.error('Error while setting token', e);
        setIsAuthorized(false);
        setIsLoading(true);
      }
    };

    setToken();

    return () => {
      if (globalState) globalState.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Condition based child route rendering
  return (
    <>
      {isLoading && (
        //Loader until authentication done
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: '#000',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <CircularProgress
            size={40}
            style={{ color: '#FFF', marginBottom: '1.25rem' }}
            thickness={4}
          />
          <Typography variant="h4" style={{ color: '#FFF' }}>
            Fetching your role information...
          </Typography>
        </Box>
      )}
      {!isLoading &&
        (isAuthorized ? <Route {...props} /> : <Redirect to="/login" />)}
    </>
  );
};
export default withRouter(AuthenticatedRoute);
